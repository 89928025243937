import React from 'react'
import img from "./Images/pr.png";
import img1 from "./Images/blender.png";
import styles from "./CourcesStyle.css";
function AnimationAndMedia() {
  return (
    <div id="padding-Civil" className="w-full flex flex-wrap flex-col items-center justify-center">
    <h1 className="mb-10 font-mv">Animation & Multimedia</h1>
    <p className="mb-16 font-letter-p text-justify">
    Animation and multimedia refer to the creation and integration of various media elements, including graphics, videos, audio, and interactive elements, to produce dynamic and engaging content. It involves the use of animation techniques, visual effects, and interactivity to convey information, entertain, or tell stories.
    </p>
    <p className="mb-16 font-letter-p text-justify">
    Animation: Animation is the process of creating the illusion of movement by rapidly displaying a sequence of static images. It can be done using traditional hand-drawn techniques, computer-generated imagery (CGI), or a combination of both. Animation is widely used in movies, television shows, video games, advertising, and educational materials.
    </p>
    <p className="mb-16 font-letter-p text-justify">
    Multimedia: Multimedia refers to the combination of different media elements such as text, images, audio, video, and interactive content. It involves the integration of these elements into a cohesive and interactive experience. Multimedia applications can be found in areas such as web design, e-learning, interactive presentations, virtual reality (VR), and augmented reality (AR). The use of animation and multimedia allows for dynamic and engaging content that captures the attention of the audience, enhances user experiences, and effectively communicates complex ideas. It requires the use of various software tools and techniques, including 2D and 3D animation software, video editing software, sound editing software, and interactive development platforms, to create compelling and interactive digital content.
    </p>
    <div
      className="sm:flex sm:flex-wrap sm:justify-evenly sm:w-[40%]"
    >
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">Premier Pro</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img1}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">Blender</h4>
      </div>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Premier Pro</span>
      <p className=" text-center font-letter  dropdown-menu">
      Premiere Pro is a professional video editing software developed by Adobe. It offers a comprehensive set of tools for video editing, color grading, audio manipulation, and visual effects. With features like timeline-based editing, multi-camera support, and seamless integration with other Adobe Creative Cloud apps, Premiere Pro empowers users to create stunning and polished video content
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Blender</span>
      <p className=" text-center font-letter  dropdown-menu">
      Blender is a powerful open-source 3D creation suite that encompasses various aspects of computer graphics, including modeling, animation, rendering, and compositing. It offers a wide range of tools for creating 3D models, realistic animations, visual effects, and interactive 3D applications. With its robust feature set and active community, Blender has become a popular choice for artists, animators, and game developers seeking to create stunning 3D content.
      </p>
    </div>
  </div>
  )
}

export default AnimationAndMedia
import React from "react";
import img from "./Image/Autocad-1.png";
import img1 from "./Image/Revit-1.png";
import img2 from "./Image/3dmax-2.png";
import img3 from "./Image/sketup.png";
import img4 from "./Image/ps.png";
import img5 from "./Image/staad-1.png";
import img6 from "./Image/Solidworks-1.png";
import img7 from "./Image/catia-1.png";
import img8 from "./Image/crio.png";
import img9 from "./Image/anysis.png";
import img10 from "./Image/coral.png";
import img11 from "./Image/ai.png";
import styles from "./HomeStyle.css";

function TopSoftwares() {
  return (
    <div
      id="Software"
      className="w-full flex flex-wrap flex-col items-center justify-evenly"
    >
      <h6>TOP SOFTWARES</h6>
      <h1 className="p-2 mb-10 font-mv">Popular Softwares Training</h1>
      <div id="top-software container mx-auto " className="">
      <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 gap-16
      
      
      ">
          <div className="flex  items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">AutoCAD</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img1}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Revit</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img2}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">3DS Max</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img3}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              Sketchup
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img4}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              Photoshop
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img5}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              Staad Pro
            </h4>
          </div>
 
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img6}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              Solid Works
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img7}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Catia</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img8}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Creo</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img9}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">ANSYS</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img10}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              CorelDraw
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img11}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2 ">
              illustrator
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSoftwares;

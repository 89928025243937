import "./App.css";
import Header from "./Components/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import img from "./whatsapp.png"
import "@fortawesome/fontawesome-free/css/all.css";
function App() {
  return (
    <div className="App relative">
      <Header />
      <Outlet />
      <Footer />
      <div className="qlwapp-container fixed right-4 bottom-8 z-[99999]">
        <a
          className="qlwapp-toggle"
          data-action="open"
          data-phone={917389424444}
          data-message="Hello!"
          role="button"
          tabIndex={0}
          target="_blank"
          href="https://web.whatsapp.com/send?phone=917389424444&text=Hello!"
        >
          <img
            src={img} className="w-16"
            alt="WhatsApp"
          />
        </a>
      </div>
    </div>
  );
}

export default App;

import React, { useRef, useState } from "react";
// Import Swiper React components
import { SwiperSlide } from "swiper/react";
import { Swiper } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/effect-cards";
import "./HomeStyle.css";
function ParallexAnimation() {
  return (
    <Swiper
      direction={"vertical"}
      cardsEffect={{
        rotate: false,
      }}
      mousewheel={true}
      grabCursor={true}
      effect={"cards"}
      modules={[Mousewheel, Autoplay]}
      autoplay={{ delay: 2000 }}
      speed={2000}
      loop={true}
    >
      <SwiperSlide className="bg-image-slider">
        <div className="w-full flex flex-col items-center justify-center h-full">
          <p className="Font-style mb-2">Smart Learning Today</p>
          <h1 className="Font-style-h1  mb-2 text-center">
            Journey into Architectural Excellence
          </h1>
        </div>
      </SwiperSlide>
      <SwiperSlide className="bg-image-slider-1">
        <div className="w-full flex flex-col items-center justify-center h-full">
          <p className="Font-style mb-2">Smart Learning Today</p>
          <h1 className="Font-style-h1 text-center">Explore the Art of Interior Design</h1>
        </div>
      </SwiperSlide>
      <SwiperSlide className="bg-image-slider-2">
        <div className="w-full flex flex-col items-center justify-center h-full">
          <p className="Font-style mb-2">Smart Learning Today</p>
          <h1 className="Font-style-h1  w-[80%] text-center">
            Design, Create, Transform in Mechanical Engineering
          </h1>
        </div>
      </SwiperSlide>
      <SwiperSlide className="bg-image-slider-3">
        <div className="w-full flex flex-col items-center justify-center h-full">
          <p className="Font-style mb-2">Smart Learning Today</p>
          <h1 className="Font-style-h1 w-[80%] text-center">
            Constructing Careers : Elevate Your Skills in Civil Engineering
          </h1>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default ParallexAnimation;

import React from "react";
import email from "./Image/email-1-q6uj3v6mtjosp11str7vg6buhl04wzpqn5jdu8rpeg.gif";
import call from "./Image/calling-q6uj8eqjupwot6g8apwyi121umm04br19n1vce17bs.gif";
import location from "./Image/location-2-q6uj8m99de6ze25b2t5z1z5qlpkxtwkvyo9r6lq1y0.gif";
import "./ContactStyle.css";

function ContactInfo() {
  return (
    <>
      <div className="flex gap-3 flex-col justify-center items-center">
        <h6 className="mt-10">Get in Touch With Us</h6>
        <h1 className="mb-10 font-mv">Contact Info</h1>
      </div>
      <div id="bg-image-Cards" className="">
        <div className="flex flex-wrap gap-x-8 gap-y-8 items-center justify-center">
          <div id="box-shodow" className="w-full max-w-sm h-52 bg-white border-gray-200 shadow p-6 flex flex-col">
            <div className="flex flex-col items-center ">
              <img className="" src={email} alt="image" />
              <div className="px-6 py-4 text-center text-center">
                <h2 className="font-bold text-xl mb-2">Mail Us</h2>
                <p className="text-gray-700 text-base">
                  Infoktrcskill@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div id="box-shodow" className="w-full max-w-sm h-52 bg-white border-gray-200 shadow p-6 flex flex-col">
            <div className="flex flex-col items-center ">
              <img className="" src={call} alt="image" />
              <div className="px-6 py-4 text-center">
                <h2 className="font-bold text-xl mb-2">Talk to us</h2>
                <p className="text-gray-700 text-base">+91 73894 24444</p>
              </div>
            </div>
          </div>
          <div id="box-shodow" className="w-full max-w-sm h-52 bg-white border-gray-200 shadow p-6 flex flex-col">
            <div className="flex flex-col items-center ">
              <img className="" src={location} alt="image" />
              <div className="px-6 py-4 text-center">
                <h2 className="font-bold text-xl mb-2"> Our Location</h2>
                <p className="text-gray-700 text-base">
                  203, Pearl Business Park, opposite Bholaram Indore
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;

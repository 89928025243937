import React from 'react'
import "./ContactStyle.css"

const FormText = () => {
  return (
    <>
      <div id="padding-Form" className='about-second'>
        <div className="2xl:container 2xl:mx-auto  md:py-12  py-9 ">
          <div className="flex flex-col lg:flex-row justify-evenly">
            <div className="w-full lg:w-[40%]">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.14502456417!2d75.8637851!3d22.6896942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd72b5178f79%3A0x82e42d313fe5bf62!2sKTRC%20Skill%20Development%20Services!5e0!3m2!1sen!2sin!4v1712064060584!5m2!1sen!2sin"
                className='w-full h-[16rem] sm:w-full lg:h-[600px] mt-20  '
                allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
            <div className="w-full lg:w-[40%]  md:mt-0  mt-3 flex flex-col">
              <div className="flex  gap-3 flex-col justify-center items-center">
                <h1 className="p-2 mb-1 font-mv">Message Us</h1>
              </div>
              <form className="flex flex-col gap-4">
                <div className="">
                  <input
                    type="text"
                    id="name"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-smfocus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
                    name="name"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="email"
                    id="email"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="number"
                    id="Phone Number"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    id="Course Name"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Course Name"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    id="Education"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Education"
                    required
                  />
                </div>

                <div className="">
                  <textarea
                    id="message"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900  rounded-sm border border-gray-300 0  dark:text-white "
                    placeholder="Message"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="text-white my-2 w-full border bg-[#00beda] hover:bg-[#00beda] focus:ring-4 focus:outline-none focus:ring-[#4490c6] font-medium rounded-smtext-sm px-5 py-2.5 text-center"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormText
import React, { useState } from "react";
import logo from "../Header/headerImg/KTRC LOGO-01-02.png";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import * as Scroll from "react-scroll";
import facebook from "./FooterImage/facebook.png";
import FooterItem from "./FooterItem";
import youtube from "./FooterImage/youtube.png";
import instagram from "./FooterImage/instagram.png";
const Footer = () => {
  const [activeFooterItem, setActiveFooterItem] = useState("");
  const [isHoveredItem, setisHoveredItem] = useState("");

  const navigate = useNavigate();

  const FooterScrollToTop = () => {
    setTimeout(async () => {
      Scroll.animateScroll.scrollToTop();
    }, 1000);
  };

  const handleFooterClick = (item) => {
      setActiveFooterItem(item);
      if (item != "home") {
        navigate(item);
        FooterScrollToTop();
      } else {
        navigate("/");
        FooterScrollToTop();
      }
    
  };

  const handleHoveredItem = (item) => {
    setisHoveredItem(item);
  };

  return (
    <>
      <footer className="relative text-surface/75  lg:text-left">
        <svg
          className="absolute top-0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 700 10"
          preserveAspectRatio="none"
        >
          <path d="M350,10L340,0h20L350,10z" fill="#0CBCD9" />
        </svg>
        <div className="Footer w-full">
          <div className="flex font-roboto-condensed flex-col md:flex-row w-[90%]">
            {/* TW Elements section */}
            <div className="flex-shrink-0 mb-4 md:mb-0 md:w-1/4 md:p-[10px] ">
              <h6 className="mb-5 flex  items-center lg:justify-center  justify-start">
                <span className="me-3 ">
                  <img src={logo} className="w-[50%]" alt="" />
                </span>
              </h6>
              <p className="text-left font w-[90%]">
                KTRC Skill Development Services is dedicated for quality job
                training for students which help them in achieving their goals.
              </p>
              <h6 className="mb-4 flex text-[20px] gap-6 mt-10">
                <Link
                  to={
                    "https://www.facebook.com/p/KTRC-3D-vision-indore-100065535461050/"
                  }
                  target="blank"
                >
                  <img src={facebook} width={30}></img>
                </Link>
                <Link
                  to={"https://www.youtube.com/@ktrc3dvision861"}
                  target="blank"
                >
                  <img src={youtube} width={30}></img>
                </Link>

                <Link
                  to={"https://www.instagram.com/ktrc3d_vision/"}
                  target="blank"
                >
                  <img src={instagram} width={30}></img>
                </Link>
              </h6>
            </div>
            {/* Useful links section */}
            <div className="w-mb-1/4 mt-2 md:mt-0 md:p-[10px] sm:w-[60%]">
              <h5 className="md:mb-2 mb-2 flex font-semibold justify-start ">
                Quick Links
              </h5>
              <FooterItem
                name="home"
                activeFooterItem={activeFooterItem}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
                handleFooterClick={handleFooterClick}
              >
                Home
              </FooterItem>

              <FooterItem
                name="about"
                activeFooterItem={activeFooterItem}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
                handleFooterClick={handleFooterClick}
              >
                About Us
              </FooterItem>
              <FooterItem
                name="Courses"
                activeFooterItem={activeFooterItem}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
                handleFooterClick={handleFooterClick}
              >
                Courses
              </FooterItem>
              <FooterItem
                name="gallery"
                activeFooterItem={activeFooterItem}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
                handleFooterClick={handleFooterClick}
              >
                Gallery
              </FooterItem>
              <FooterItem
                name="contact"
                activeFooterItem={activeFooterItem}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
                handleFooterClick={handleFooterClick}
              >
                Contact Us
              </FooterItem>
            </div>
            <div className="w-md-1/4 mt-2 md:mt-0 md:p-[10px]">
              <h5 className="md:mb-4 mb-2 flex font-semibold  justify-start">
                Contact Us
              </h5>
              <div className="flex mb-5 ">
                <i aria-hidden="true" className="fas fa-arrow-right  mr-5" />
                <a>Infoktrcskill@gmail.com</a>
              </div>
              <div className="flex mb-5">
                <i aria-hidden="true" className="fas fa-arrow-right mr-5" />
                <a>+91 73894 24444</a>
              </div>
              <div className="flex mb-5">
                <i aria-hidden="true" className="fas fa-arrow-right mr-5" />
                <a className="w-[80%]">
                  203, Pearl Business Park, opposite Bholaram Ustad Marg,
                  Bhawarkua, Vishnu Puri Colony, Indore, Madhya Pradesh 452001
                </a>
              </div>
            </div>
            {/* Contact section */}
            <div className=" w-full md:w-1/4 mt-2 md:mt-0 md:p-[10px]">
              <div className="flex gap-2  justify-center"></div>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.14502456417!2d75.8637851!3d22.6896942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd72b5178f79%3A0x82e42d313fe5bf62!2sKTRC%20Skill%20Development%20Services!5e0!3m2!1sen!2sin!4v1712064060584!5m2!1sen!2sin"
                  className="w-full h-[16rem] lg:w-[280px] lg:h-[300px] "
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
        {/*Copyright section*/}
        <div className="text-white p-[10px] text-[14px] bg-[#4F4F4F]  text-center">
          All Rights Reserved. © 2024 Design & Developed By <a href="https://ksvsofttech.com/" className="text-white" target="blank"> KSVSoftTech </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;

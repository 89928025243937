import React from 'react'
import ktrcSkillImage from "./Image/Skill.png"
import "./HomeStyle.css"
function KTRCSkill() {
    return (
        <div id="Padding-skill" className='flex flex-col lg:flex-row items-center justify-center'>
        <div className="lg:w-[40%] justify lg:p-18">
            <img src={ktrcSkillImage} className="object-center object-cover align-middle lg:align-center transition-all duration-500 delay-500 transform" />
        </div>
        <div className=" lg:w-[50%] justify lg:p-16">
            <h5 className="mb-2 text-color font-mv">Welcome To - KTRC Skill Development Services</h5>
            <p className="mb-5 text-base text-justify ">KTRC Skill Development Services is a premier provider of professional training and internship programs for students in the fields of Civil Engineering, Interior Design, and Architecture. We also offer specialized training in survey instruments such as Total Station and DGPS. Our comprehensive training programs are designed to equip students with the skills and knowledge necessary to succeed in their careers. Our experienced trainers provide hands-on training in the latest software and technologies, as well as on-site training opportunities to help students gain practical experience in real-world settings. At KTRC Skill Development Services, we are committed to helping students achieve their goals and become leaders in their fields.</p>
        </div>
    </div>
    )
}

export default KTRCSkill
import React, { useState } from 'react';
import Tab from './Tab';
import { AllSection} from './Constant';
import './homestyle.css'

const AllTabs = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div id="bg-image-gallery" className='md:p-5 p-3'>
            <div className="flex  gap-3 flex-col justify-center items-center">
                {/* <nav className=" flex justify-center flex-wrap " aria-label="Tabs" role="tablist">
                    <Tab id={1} label="Gallery" isActive={activeTab === 1} handleClick={handleTabClick} />
                </nav> */}
              <h1 className="p-2 mb-1 mt-20 font-mv">Gallary</h1>
            </div>
            <div className="px-4 xl:px-4">
                <div id="horizontal-alignment-1" role="tabpanel" aria-labelledby="horizontal-alignment-item-1" hidden={activeTab !== 1}>
                   <AllSection />
                </div>
                <div id="horizontal-alignment-3" role="tabpanel" aria-labelledby="horizontal-alignment-item-3" hidden={activeTab !== 6}>
                    <p className="text-gray-500 h-20 dark:text-gray-400">
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AllTabs;

import React from 'react'
import PlacementAsistanceCard from './PlacementAssistanceCards'
import TopSoftwares from './TopSoftwares'
import KTRCSkill from './KTRCSkill'
import Courses from './Courses'
import CareerOppurtunities from './CareerOppurtunities'
import Slider from './Slider'
import Empowring from './Empowring'
import ParallexAnimation from './ParallexAnimation'
function Home() {
  return (
   <>
   <ParallexAnimation/>
   <PlacementAsistanceCard/>
   <TopSoftwares/>
   <KTRCSkill/>
   <Courses/>
   <CareerOppurtunities/>
   <Slider/>
   <Empowring/>
   </>
  )
}

export default Home

import React from 'react'
import img from "./Images/Autocad-1.png";
import img1 from "./Images/crio (1).png";
import img2 from "./Images/catia-1 (1).png";
import img3 from "./Images/anysis (1).png";
import img4 from "./Images/Solidworks-1 (1).png";
import styles from "./CourcesStyle.css";
function MechnicalDesign() {
  return (
    <div id="padding-Civil" className="w-full flex flex-wrap flex-col items-center justify-center">
    <h1 className="mb-10 font-mv">Mechanical Design</h1>
    <p className="mb-16 font-letter-p text-justify">
    Mechanical design is a branch of engineering that focuses on the creation and development of mechanical systems, components, and products. It involves the application of scientific and mathematical principles to design, analyze, and optimize mechanical structures and mechanisms. In mechanical design, engineers work on a wide range of projects, including machinery, vehicles, consumer products, and industrial equipment. They consider factors such as functionality, performance, safety, durability, and manufacturability during the design process.
    </p>
    <div
      className="sm:flex sm:flex-wrap sm:justify-between sm:w-[80%]"
    >
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">AutoCAD</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img1}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">Creo</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img2}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">Catia</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img3}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">
        ANSYS
        </h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img4}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">
        Solid Works
        </h4>
      </div>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
    <span className="Font-Family md:text-center ">AutoCAD</span>
      <p className=" text-center font-letter  dropdown-menu">
      AutoCAD is a powerful computer-aided design (CAD) software widely used in civil design. It provides a comprehensive set of tools and features specifically tailored for civil engineering and design projects. With AutoCAD, civil designers can create accurate and detailed 2D and 3D drawings, models, and documentation for a wide range of infrastructure projects.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Creo</span>
      <p className=" text-center font-letter dropdown-menu ">
      Creo, formerly known as Pro/ENGINEER, is a suite of computer-aided design (CAD) software tools developed by PTC (Parametric Technology Corporation). It is widely used in mechanical engineering and product design industries to create, analyze, and optimize 3D models of mechanical components and assemblies. Creo offers a comprehensive set of tools for 2D drafting, 3D modeling, simulation, and manufacturing. It supports parametric modeling, which allows engineers to create models with design intent and associativity. This means that any changes made to the model automatically propagate throughout the design, ensuring consistency and efficiency.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center ">Catia</span>
      <p className=" text-center font-letter dropdown-menu ">
      CATIA (Computer-Aided Three-Dimensional Interactive Application) is a versatile and comprehensive software suite developed by Dassault Systèmes. It is widely used in various industries, including automotive, aerospace, industrial equipment, and consumer goods, for computer-aided design (CAD), engineering, and product lifecycle management (PLM).
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Solid Works</span>
      <p className=" text-center font-letter  dropdown-menu">
      SolidWorks is a powerful CAD software for 3D modeling, simulation, and documentation. It offers user-friendly tools for design, assembly, analysis, and documentation, enabling efficient product development and engineering.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center ">Unigraphics</span>
      <p className=" text-center font-letter  dropdown-menu">
      Unigraphics, also known as NX, is a leading computer-aided design, manufacturing, and engineering (CAD/CAM/CAE) software developed by Siemens Digital Industries Software. It is widely used in various industries, including automotive, aerospace, and industrial machinery.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">ANSYS</span>
      <p className=" text-center font-letter  dropdown-menu">
      ANSYS is a powerful simulation software suite widely used in engineering and scientific fields. It offers a range of simulation tools for structural analysis, fluid dynamics, electromagnetics, and multiphysics simulations.
      </p>
    </div>
  </div>
  )
}

export default MechnicalDesign
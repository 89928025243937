import React from 'react'
import img from "./Images/ps (1).png";
import img1 from "./Images/coral (1).png";
import img2 from "./Images/ai (1).png";
import styles from "./CourcesStyle.css";
function GraphicDesign() {
  return (
    <div id="padding-Civil" className="w-full flex flex-wrap flex-col items-center justify-center">
    <h1 className="mb-10 font-mv">Graphic Design</h1>
    <p className="mb-16 font-letter-p text-justify">
    Graphic design is the art and practice of creating visual content to communicate messages and convey information. It involves the use of typography, images, colors, and layout techniques to design various materials such as logos, advertisements, brochures, websites, and packaging.
    </p>
    <p className="mb-16 font-letter-p text-justify">
    Graphic designers utilize software tools like Adobe Photoshop, Illustrator, and InDesign to create and manipulate visual elements. They consider factors such as aesthetics, brand identity, target audience, and the intended message when designing. The role of a graphic designer includes conceptualizing ideas, creating visual compositions, selecting appropriate colors and fonts, and organizing elements to create a visually appealing and effective design. They also need to understand design principles, including balance, contrast, hierarchy, and alignment, to create visually engaging and impactful designs. Graphic design plays a crucial role in marketing, branding, and communication. It helps businesses and organizations convey their message effectively, establish their identity, and connect with their audience visually.
    </p>
    <div
      className="sm:flex sm:flex-wrap sm:justify-evenly sm:w-[60%]"
    >
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">Photoshop</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img1}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">CorelDraw</h4>
      </div>
      <div className="flex items-center gap-4 flex-col">
        <img
          decoding="async"
          width={115}
          height={114}
          src={img2}
          className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
          alt=""
          style={{
            width: "8rem",
            height: "99.13%",
            maxWidth: 115,
            transition: "filter 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.target.style.filter = "brightness(1.2)";
          }}
          onMouseLeave={(e) => {
            e.target.style.filter = "brightness(1)";
          }}
        />
        <h4 className="Font-Family-Text font-bold text-xl mb-2">illustrator</h4>
      </div>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Photoshop</span>
      <p className=" text-center font-letter  dropdown-menu">
      Photoshop is a versatile image editing software developed by Adobe. It offers a wide range of tools for retouching, enhancing, and manipulating images. With features like layers, filters, and brushes, Photoshop enables users to create stunning visuals, edit photos, design graphics, and generate professional artwork for various mediums.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Corel Draw</span>
      <p className=" text-center font-letter dropdown-menu">
      CorelDRAW is a versatile vector graphics editor used for graphic design and layout creation. It offers tools for vector illustration, page layout, image editing, and typography. With its extensive feature set, CorelDRAW enables users to create professional designs for print, web, and other digital platforms.
      </p>
    </div>
    <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
      <span className="Font-Family md:text-center">Illustrator</span>
      <p className=" text-center font-letter  dropdown-menu">
      Illustrator is a powerful vector graphics editor developed by Adobe. It is widely used for creating illustrations, icons, logos, and other scalable artwork. With precise tools for drawing, editing, and typography, Illustrator allows users to create professional and intricate designs for print, web, and multimedia projects.
      </p>
    </div>
  </div>
  )
}

export default GraphicDesign
import React from "react";
import civil from "./Image/civil-q6ioco2rnw50kha152c2bm3yhejwyndpppjyfdil7i.png";
import interior from "./Image/Archi-q6iof2jx4nfe8lsf43pqp0ad4upomsx0plkmktydb2.png";
import mechnical from "./Image/mechn-q6ioikiakg7tgcpgom5qz4i4qhgva8t1uwzovwrm5a.png";
import animation from "./Image/animat-q6iomdqqa9fojf64f9ha35vfgqogi1xv1s8kyb44wu.png";
import graphic from "./Image/gd-q6iomdqqa9fojf64f9ha35vfgqogi1xv1s8kyb44wu.png";
import "./HomeStyle.css";
function Courses() {
  return (
    <>
      <div
        id="bg-image-Cources"
        className="flex flex-col text-justify items-center"
      >
        <h6 className="p-2">SELECT COURSE</h6>
        <h1 className="p-2 mb-10 font-mv">Explore Popular Courses</h1>
        <div className=" flex flex-wrap gap-x-4 gap-y-4  items-center justify-center">
          <div
            className="w-full relative max-w-sm bg-white border-gray-200 rounded-lg shadow p-6"
          >
            <div className="background-overlay bg-1"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={civil}
                alt="image"
              />
              <div className="px-6 py-4 text-center text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Civil Design
                </div>
                <p className="text-gray-700 text-base text-center">
                  AutoCAD, Revit Architecture, Staad pro, Total Station, DGPS
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full max-w-sm relative bg-white  border-gray-200 rounded-lg shadow   p-6 "
          >
             <div className="background-overlay bg-2"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={interior}
                alt="image"
              />
              <div className="px-6 py-4 text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Interior & Architecture Design
                </div>
                <p className="text-gray-700 text-base text-center">
                  AutoCAD, Revit Architecture, 3dsMax + Vray, Lumion, Sketch Up+
                  Vray, Revit
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full relative max-w-sm bg-white  border-gray-200 rounded-lg shadow  p-6 "
          >
            <div className="background-overlay bg-3"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={mechnical}
                alt="image"
              />
              <div className="px-6 py-4 text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Mechanical Design
                </div>
                <p className="text-gray-700 text-base text-center">
                  AutoCAD ,Creo ,Catia ,Solid Works ,Unigraphics, ANSYS
                </p>
              </div>
            </div>
          </div>
          <div
            id="bg-image-4"
            className="w-full relative max-w-sm bg-white  border-gray-200 rounded-lg shadow  p-6 "
          >
            <div className="background-overlay bg-4"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={animation}
                alt="image"
              />
              <div className="px-6 py-4 text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Animation & Multimedia
                </div>
                <p className="text-gray-700 text-base text-center pb-6">
                  Premier Pro, Blender 
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-full relative max-w-sm bg-white  border-gray-200 rounded-lg shadow  p-6 "
          >
             <div className="background-overlay bg-5"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={mechnical}
                alt="image"
              />
              <div className="px-6 py-4 text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Personality Development
                </div>
                <p className="text-gray-700 text-base text-center">
                  Clarity, Confidence, fluency, Active Listening, Pronunciation
                  etc.
                </p>
              </div>
            </div>
          </div>
          <div
            id="bg-image-6"
            className="w-full relative max-w-sm bg-white  border-gray-200 rounded-lg shadow  p-6 "
          >
            <div className="background-overlay bg-6"></div>
            <div className="flex flex-col items-center ">
              <img
                className="w-24 h-24 mb-10 shadow-lg"
                src={graphic}
                alt="image"
              />
              <div className="px-6 py-4 text-center">
                <div className="font-montserrat-alternates text-xl font-bold capitalize">
                  Graphic Design
                </div>
                <p className="text-gray-700 text-base pb-6 text-center">
                  Photoshop ,Corel Draw ,Illustrator
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;

import React from "react";
import ktrcSkillImage from "../Home/Image/pngwing.com-12.png";
import "./AboutStyle.css"
function SkillDevelopment() {
  return (
    <div id="padding-Development" className="flex flex-col text-justify ">
      <div className="flex w-full justify-end font-mv">
        <div className="w-full md:w-[80%]" >
      <h6 className="p-2">ABOUT US</h6>
      <h1 className="p-2 mb-10 font-mv">
      Welcome to KTRC Skill Development Services
      </h1>
      </div>
      </div>
      <div
        className="flex flex-col lg:flex-row items-center justify-center"
      >
        <div className="w-full lg:w-[50%] justify lg:p-10">
          <img
            src={ktrcSkillImage}
            className="object-center object-cover align-middle lg:align-center transition-all duration-500 delay-500 transform"
          />
        </div>
        <div className="w-full lg:w-[50%] justify lg:p-10">
          <div className="font-bold text-xl mb-2 text-left font-family-ktrc">
            KTRC Skill Development Service is a leading provider of
            comprehensive training and internship opportunities in both
            technical and non-technical fields. As a subsidiary of KTRC
            Engineering Services, which was founded by Er. Chitranshu Kolhekar
            in 2014, we have a strong foundation and expertise in the industry.
          </div>
          <p className="mb-5 text-base text-left">
            Our primary focus is to equip students and job seekers with the
            necessary skills and knowledge to build successful careers. We offer
            specialized training programs in various disciplines, including
            civil engineering, architecture, interior design, mechanical
            engineering, graphic design, animation, and more.
          </p>
          <p className="mb-5 text-base  text-left">
            With a team of experienced instructors and industry professionals,
            we provide hands-on training, practical workshops, and real-world
            projects to ensure our participants gain valuable insights and
            develop practical skills. We believe in nurturing talent and
            empowering individuals to excel in their chosen fields. At KTRC
            Skill Development Service, we understand the importance of bridging
            the gap between academic knowledge and industry requirements.
            Therefore, we emphasize a holistic approach to training,
            encompassing technical expertise, soft skills development, and
            career guidance.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SkillDevelopment;

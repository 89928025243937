import React from "react";
import img from "./Images/Autocad-1.png";
import img1 from "./Images/ts-1.png";
import img2 from "./Images/dgps.png";
import img3 from "./Images/Revit-1.png";
import img4 from "./Images/staad-1.png";
import styles from "./CourcesStyle.css";
function CivilDesing() {
  return (
    <>
      <div id="padding-Civil" className="w-full flex flex-wrap flex-col items-center justify-center">
        <h1 className="mb-10 font-mv">Civil Design</h1>
        <p className="mb-16 font-letter-p text-justify">
          Civil design refers to the process of planning, designing, and
          creating the physical infrastructure that supports human activities
          and communities. It encompasses various engineering disciplines,
          including civil engineering, structural engineering, transportation
          engineering, geotechnical engineering, and hydraulic engineering. The
          primary goal of civil design is to develop safe, efficient, and
          sustainable structures and systems that meet the needs of society.
          Civil designers work on a wide range of projects, such as roads,
          bridges, buildings, airports, water supply and sewage systems, dams,
          and other infrastructure projects.
        </p>
        <div
          className="sm:flex sm:flex-wrap sm:justify-between sm:w-[80%]"
        >
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">AutoCAD</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img1}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Total Station</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img2}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">DGPS</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img3}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
            Revit
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img4}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
              Photoshop
            </h4>
          </div>
        </div>
        <div className="flex gap-3 flex-col justify-center pt-20 items-center">
          <span className="Font-Family ">AutoCAD</span>
          <p className=" text-center font-letter dropdown-menu ">
          AutoCAD is a powerful computer-aided design (CAD) software widely used in civil design. It provides a comprehensive set of tools and features specifically tailored for civil engineering and design projects. With AutoCAD, civil designers can create accurate and detailed 2D and 3D drawings, models, and documentation for a wide range of infrastructure projects.
          </p>
        </div>
        <div className="flex gap-3 flex-col justify-center pt-20 items-center">
          <span className="Font-Family">Revit Architecture</span>
          <p className=" text-center font-letter dropdown-menu ">
          Revit is a powerful Building Information Modeling (BIM) software that is widely used in civil design and construction projects. It offers a comprehensive set of tools and features specifically tailored for architects, engineers, and construction professionals involved in the design and documentation of buildings and infrastructure.
          </p>
        </div>
        <div className="flex gap-3 flex-col justify-center pt-20 items-center">
          <span className="Font-Family">Staad pro</span>
          <p className=" text-center font-letter dropdown-menu ">
          STAAD.Pro is a widely used software for structural analysis and design in civil engineering. It is a powerful tool that enables engineers to analyze and design a variety of structures, including buildings, bridges, towers, and other types of infrastructure.
          </p>
        </div>
        <div className="flex gap-3 flex-col justify-center pt-20 items-center">
          <span className="Font-Family">Total Station</span>
          <p className=" text-center font-letter dropdown-menu ">
          A total station is a surveying instrument used in civil engineering and construction projects to measure angles, distances, and elevations with a high level of accuracy. It combines electronic distance measurement (EDM) technology with an electronic theodolite to perform precise measurements and data collection in the field
          </p>
        </div>
        <div className="flex gap-3 flex-col justify-center pt-20 items-center">
          <span className="Font-Family">DGPS</span>
          <p className=" text-center font-letter dropdown-menu ">
          DGPS stands for Differential Global Positioning System. It is a navigation and positioning technology that enhances the accuracy of standard GPS (Global Positioning System) measurements. DGPS uses a network of fixed ground-based reference stations to provide corrections for GPS signals, resulting in improved positional accuracy.
          </p>
        </div>
      </div>
    </>
  );
}

export default CivilDesing;

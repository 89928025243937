import React from 'react'
import "./HomeStyle.css"
import { Link } from 'react-router-dom'
function Empowring() {
  return (
    <>
      <div id="padding-Empowring" className='relative bg-[#5E5E5E] flex flex-col justify-center items-center'>
        <svg className="absolute top-0" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 700 10" preserveAspectRatio="none">
          <path d="M350,10L340,0h20L350,10z" fill="#fff"></path>
        </svg>
        <h1 className='h1 mb-5 font-mv-h6 '>Unleashing Skills, Empowering Futures</h1>
        <p className='p mb-9 w-full lg:w-[65%]  text-center font-mv-enroll'>Enroll now in our skill development programs and unlock your potential for success. Acquire in-demand skills, gain industry-recognized certifications, and open doors to new career opportunities. Don't miss out!</p>
        <Link to="/Enquiry" className='button'>Enroll Now</Link>
      </div>
    </>
  )
}

export default Empowring
import React from "react";
import "./Enquiry.css"

const Enquiry = () => {
  return (
    <>
    <div className="p-4 p-md-0">
      <div className="flex gap-3 flex-col justify-center items-center">
        <h6 className="mt-10">For Enquiry</h6>
        <h1 className="mb-1 font-mv">Fill The Form Below</h1>
      </div>
      <div className="about-second">
        <div className="2xl:container 2xl:mx-auto md:py-12  py-9 ">
          <div className="flex flex-col lg:flex-row gap-3 justify-center">
            <div className="w-full lg:w-2/4  md:mt-0  mt-3 flex flex-col ">
              <form className="flex flex-col gap-6">
                <div className="">
                  <input
                    type="text"
                    id="name"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-smfocus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   "
                    name="name"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="email"
                    id="email"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="number"
                    id="Phone Number"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    id="Course Name"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Course Name"
                    required
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    id="Education"
                    className="  border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                    placeholder="Education"
                    required
                  />
                </div>

                <div className="">
                  <textarea
                    id="message"
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900  rounded-sm border border-gray-300 0  dark:text-white "
                    placeholder="Message"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="text-white my-2 w-full border bg-[#00beda] hover:bg-[#00beda] focus:ring-4 focus:outline-none focus:ring-[#4490c6] font-medium rounded-smtext-sm px-5 py-2.5 text-center"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Enquiry;

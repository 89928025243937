import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import img from "./Image/2.png";
import img1 from "./Image/hjh.png";
import img2 from "./Image/gjh.png";
import img3 from "./Image/1-1.png";
import img4 from "./Image/9.png";
import img5 from "./Image/20.png";
import img6 from "./Image/00.png";
import img7 from "./Image/8.png";
import img8 from "./Image/4.png";
import img9 from "./Image/5.png";
import img10 from "./Image/4.png";
import img11 from "./Image/3.png";
import "@splidejs/react-splide/css";

const Slider = () => {
  const [showtext, setShowText] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    setShowText(false);
    setTimeout(() => {
      setShowText(true);
    }, 100); 
  }, [activeSlide]);

  return (
    <>
      <div className="flex gap-3 flex-col justify-center items-center">
        <h6 className="mt-10">TESTIMONIALS</h6>
        <h1 className="mb-1 font-mv">What Students Saying</h1>
      </div>
      <Splide
        options={{
          perPage: 1,
          pagination: true,
          fixedWidth: "100%",
          height: "460px",
          autoplay: true,
          speed: 1000,
          type: "loop",
        }}
        aria-label="My Favorite Images"
        onMoved={(splide, newIndex) => setActiveSlide(newIndex)}
      >
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img className="w-16 h-16 mb-3 " src={img} alt="Profile" />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Shubham Roy
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " All the members are saying that they achieve from there. For
                me Always a question Why you should try this ? Let's given an
                honest answer to this question. All of you know that everyone
                have some talent but no one wanna talk about it because they'll
                make fool out of it that's why some people can't say it. The
                KTRC team will help you to develop that skill from scratch to
                Advanced level that's why you should try this institution. "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img1}
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Piyush Mandloi
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " Providing field knowledge and instruments operating skill
                together is very good opportunity for students to develop
                efficient skills. Very good services provided by very efficient
                technical staff of KTRC team. "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img2}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Asif Multani
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " Practical experience as well as personal development. Great
                guidance to students of every field or branches, must visit 💥 "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img3}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Mohit Birla
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " My experience was Very great 👍 😃 this is the first time I
                see everything is there to improve our career. "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img4}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                lalitesh Sinha
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " KTRC works enormously done good job for the student site
                knowledge & job exposure. "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img5}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Tarun Raj
              </h5>
              <h3 className="flex w-1/2 text-center font-mv-slider">
                " Good place for practical learning top qualified and supporting
                faculties "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img6}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Arpit Sethia
              </h5>
              <h3 className="flex text-center  font-mv-slider">
                " Nice place for civil engineering training qualified staff "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img7}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Bittu Awasiya
              </h5>
              <h3 className="flex text-center  font-mv-slider">
                " Best institute of practical training "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img8}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Ajit Dhurve
              </h5>
              <h3 className="flex text-center  font-mv-slider">
                " KTRC is taking a great initiative 👏🏻 "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img9}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Aamir Ansari
              </h5>
              <h3 className="flex text-center  font-mv-slider">" Best training places "</h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img10}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Ashish Devray
              </h5>
              <h3 className="flex text-center  font-mv-slider">
                " Good faculties and management "
              </h3>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="w-full flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
              <img
                className="w-16 h-16 mb-3 rounded-full shadow-lg"
                src={img11}
                alt="Profile"
              />
              <h5 className="mb-2 text-xl font-cabin text-black dark:text-gray-900 leading-6">
                Jaypal Rathour
              </h5>
              <h3 className="flex w-1/2 text-center  font-mv-slider">
                KTRC Services are doing exceptional Job for youngsters by
                enhancing technical skills "
              </h3>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </>
  );
};

export default Slider;

import React from "react";
import img from "./Images/Autocad-1.png";
import img1 from "./Images/Revit-1.png";
import img2 from "./Images/vray.png";
import img3 from "./Images/3dmax-2 (1).png";
import img4 from "./Images/sketup (1).png";
import img5 from "./Images/staad-1.png";
import styles from "./CourcesStyle.css";
function CivilDesing() {
  return (
    <>
      <div id="padding-Civil" className="w-full flex flex-wrap flex-col items-center justify-center">
        <h1 className="mb-10 font-mv">Interior and Architecture</h1>
        <p className="mb-16 font-letter-p text-justify">
        Interior design and architecture intersect to create captivating spaces that harmonize aesthetics and functionality. Architects focus on the overall structure, layout, and form of a building, while interior designers enhance it with thoughtful furniture placement, lighting, materials, and colors. They collaborate to ensure a seamless integration of exterior and interior elements, resulting in cohesive, inviting environments. By considering spatial flow, human experience, and the client's vision, interior and architecture professionals transform spaces into inspiring, purposeful settings that leave a lasting impression.
        </p>
        <div
          className="sm:flex sm:flex-wrap sm:justify-between sm:w-[80%]"
        >
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">AutoCAD</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img1}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Revit</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img2}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">Vray</h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img3}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
            3DS Max
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img4}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
            Sketchup
            </h4>
          </div>
          <div className="flex items-center gap-4 flex-col">
            <img
              decoding="async"
              width={115}
              height={114}
              src={img5}
              className={`${styles.image} elementor-animation-grow attachment-full size-full wp-image-1556`}
              alt=""
              style={{
                width: "8rem",
                height: "99.13%",
                maxWidth: 115,
                transition: "filter 0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.target.style.filter = "brightness(1.2)";
              }}
              onMouseLeave={(e) => {
                e.target.style.filter = "brightness(1)";
              }}
            />
            <h4 className="Font-Family-Text font-bold text-xl mb-2">
            Staad Pro
            </h4>
          </div>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">AutoCAD</span>
          <p className=" text-center font-letter dropdown-menu">
          AutoCAD is a powerful computer-aided design (CAD) software widely used in civil design. It provides a comprehensive set of tools and features specifically tailored for civil engineering and design projects. With AutoCAD, civil designers can create accurate and detailed 2D and 3D drawings, models, and documentation for a wide range of infrastructure projects.
          </p>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">Revit Architecture</span>
          <p className=" text-center font-letter dropdown-menu">
          Revit is a powerful Building Information Modeling (BIM) software that is widely used in civil design and construction projects. It offers a comprehensive set of tools and features specifically tailored for architects, engineers, and construction professionals involved in the design and documentation of buildings and infrastructure.
          </p>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">3dsMax + Vray</span>
          <p className=" text-center font-letter dropdown-menu">
          3ds Max and V-Ray are powerful tools widely used in the field of architectural visualization and 3D rendering. 3ds Max is a popular 3D modeling and animation software that provides a comprehensive set of tools for creating realistic 3D scenes, including modeling, texturing, lighting, and animation capabilities. V-Ray, on the other hand, is a highly regarded rendering engine that works seamlessly with 3ds Max, enabling users to achieve photorealistic renders with advanced lighting and shading techniques. The combination of 3ds Max and V-Ray empowers architects and designers to bring their visions to life with stunning visual representations of their projects.
          </p>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">Lumion</span>
          <p className=" text-center font-letter dropdown-menu">
          Lumion is a powerful real-time 3D visualization software commonly used in architecture, urban planning, and landscaping. It allows architects, designers, and visualization professionals to create immersive and realistic visualizations of their projects quickly and easily. With Lumion, users can import 3D models from various design software, apply materials, add lighting and vegetation, and create dynamic environments. It offers a vast library of pre-built objects, textures, and effects that can be used to enhance the visual quality and atmosphere of the scenes. Lumion's real-time rendering capabilities enable users to make instant changes and explore different design options, providing a valuable tool for presenting and communicating architectural ideas to clients and stakeholders.
          </p>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">Sketch Up + Vray</span>
          <p className=" text-center font-letter dropdown-menu">
          SketchUp and V-Ray are commonly used together in the architectural and design industries to create detailed 3D models and produce high-quality renderings. SketchUp is a user-friendly 3D modeling software that enables designers to create precise architectural models and visualizations. It offers intuitive tools for creating geometric shapes, editing surfaces, and organizing components. V-Ray, on the other hand, is a renowned rendering engine that integrates seamlessly with SketchUp. V-Ray enhances the visual quality of SketchUp models by simulating realistic lighting, materials, and textures. It offers advanced features such as global illumination, ambient occlusion, and ray tracing, which contribute to creating lifelike renderings. The combination of SketchUp and V-Ray allows designers to model and visualize architectural concepts, experiment with different design options, and present their ideas in a visually compelling manner. It provides a powerful workflow for professionals seeking to achieve realistic and immersive architectural visualizations.
          </p>
        </div>
        <div className="flex gap-3 flex-col md:justify-center pt-20 md:items-center">
          <span className="Font-Family md:text-center">Revit MEP</span>
          <p className=" text-center font-letter dropdown-menu">
          Revit MEP (Mechanical, Electrical, and Plumbing) is a software application specifically designed for building information modeling (BIM) in the field of mechanical, electrical, and plumbing engineering. It is part of the Autodesk Revit suite and is widely used by MEP professionals for designing, documenting, and analyzing building systems.
          </p>
        </div>
      </div>
    </>
  );
}

export default CivilDesing;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItems = ({
  name,
  handleNavItemClick,
  children,
  isHoveredItem,
  handleHoveredItem,
}) => {
  const location = useLocation();
  const isActive =
    (location.pathname === "/" && name === "home") ||
    location.pathname == `/${name}`;
  const isHovered = name === isHoveredItem;
  return (
    <li
      className=" max-lg:py-3 lg:px-5 px-1 relative font-roboto-condensed "
      onClick={() => {
        if (children !== "Courses") {
          handleNavItemClick(name);
        }
      }}
      onMouseEnter={() => handleHoveredItem(name)}
      onMouseLeave={() => handleHoveredItem("")}
    >
      <a
        id="text-font"
        className={`hover:text-[#33C2FC] hover:cursor-pointer ${
          isActive || isHovered ? "text-[#33C2FC]" : ""
        } font-semibold block text-[20px] `}
      >
        {children !== "Courses" ? (
          children
        ) : (
          <div className="relative !py-0  inline-block text-left">
            <div className="group ">
              <Link to={"/civil"}>
                <a
                  className={`hover:text-[#33C2FC]  ${
                    isActive || isHovered ? "text-[#33C2FC]" : ""
                  } hover:cursor-pointer  font-semibold block text-[16px]`}
                >
                  Courses
                  <span>
                    <i className="fa-solid fa-angle-down ml-1" />
                  </span>
                </a>
              </Link>
              <div className="absolute left-0 w-72 origin-top-left z-[9999] bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                <div className="py-1">
                  <Link
                    to={"/civil"}
                    className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Civil Design
                  </Link>
                  <Link
                    to={"/interiorAndArchitecture"}
                    className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Interior And Architecture Design
                  </Link>
                  <Link
                    to={"/mechanicalDesign"}
                    className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Mechnical Design
                  </Link>
                  <Link
                    to={"/animationAndMedia"}
                    className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Animation And Multimedia
                  </Link>
                  <Link
                    to={"/graphicDesign"}
                    className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Graphic Design
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </a>
      {/* {(isActive || isHovered) && <div className="active-line absolute w-full h-1 bg-[#33C2FC] bottom-0"></div>} */}
    </li>
  );
};

export default NavItems;

import React from 'react'
import SkillDevelopment from './SkillDevelopment'
import Content from './Content'
import "./AboutStyle.css"

function About() {
  return (
    <div id="bg-image">
      <SkillDevelopment/>
      <Content/>
    </div>
  )
}

export default About

import React from "react";
import "./AboutStyle.css";

function Content() {
  return (
    <>
      <div className="flex flex-col items-center content-padding">
        <div className="w-full flex flex-col items-center">
          <div className=" mb-20 lg:w-[80%] flex flex-col lg:flex-row items-start">
            <div class="font-bold text-xl mb-2 lg:mr-28 font-family-ktrc">Vision </div>
            <div className="flex flex-col items-center">
              <p className=" text-left">
                To be a premier skill development service provider, empowering
                individuals with the knowledge and expertise to succeed in their
                chosen fields, and contributing to the growth and progress of
                industries worldwide.
              </p>
            </div>
          </div>
          <hr className="lg:w-[80%] border-black "></hr>
          <div className=" mb-20 lg:w-[80%] mt-20 flex flex-col lg:flex-row items-start">
            <div class="font-bold text-xl mb-2 lg:mr-24 font-family-ktrc">Mission</div>
            <div className="flex flex-col items-center">
              <p className=" text-left">
                Our mission is to provide comprehensive and industry-relevant
                training programs and internship opportunities, equipping
                students and job seekers with the skills, practical experience,
                and professional guidance needed to excel in their careers. We
                strive to foster a culture of continuous learning, innovation,
                and personal growth, enabling individuals to meet the evolving
                challenges of the global workforce. Through our dedication to
                excellence and commitment to our participants' success, we aim
                to be the catalyst for their professional advancement and
                contribute to building a skilled workforce for a brighter
                future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;

import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/css/bundle";
import "./index.css";
import Home from "./Components/Home/Home";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AOS from "aos";
import "aos/dist/aos.css";
import About from "./Components/About/About";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Gallery from "./Components/Gallery/AllTabs";
import ContactUs from "./Components/Contact/Contact";
import Cources from "./Components/Courses/Courses";
import Enquiry from "./Components/EnquiryForm/Enquiry";
import AnimationAndMedia from "./Components/Courses/AnimationAndMedia";
import CivilDesing from "./Components/Courses/CivilDesing";
import InteriorAndDesign from "./Components/Courses/InteriorAndDesign";
import MechnicalDesign from "./Components/Courses/MechnicalDesign"
import GraphicDesign from "./Components/Courses/GraphicDesign";
// Initialize AOS
AOS.init();
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <> Error Page not found</>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/civil",
        element: <Cources />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/Enquiry",
        element: <Enquiry />,
      },

      {
        path: "/interiorAndArchitecture",
        element: <InteriorAndDesign/>
      },
      {
        path: "/mechanicalDesign",
        element: <MechnicalDesign/>
      },
      {
        path: "/animationAndMedia",
        element: <AnimationAndMedia />,
      },
      {
        path: "/graphicDesign",
        element: <GraphicDesign />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);

reportWebVitals();

import React from 'react'
import ContactInfoCards from './ContactInfoCards'
import FormText from "./FormText"
function Contact() {
  return (
    <div className='padding'>
      <ContactInfoCards/>
      <FormText/>
    </div>
  )
}

export default Contact

import React, { useState, useCallback } from 'react'
import ImageViewer from 'react-simple-image-viewer';

// Interior Image -
import Int_photo_1 from './INTERIOR/1.jpg'
import Int_photo_2 from './INTERIOR/2.jpg'
import Int_photo_3 from './INTERIOR/3.jpg'
import Int_photo_4 from './INTERIOR/4.jpg'
import Int_photo_5 from './INTERIOR/5.jpg'
import Int_photo_6 from './INTERIOR/6.jpg'
import Int_photo_7 from './INTERIOR/7.jpg'
import Int_photo_8 from './INTERIOR/8.jpg'
import Int_photo_9 from './INTERIOR/9.jpg'
import Int_photo_10 from './INTERIOR/10.jpg'
import Int_photo_11 from './INTERIOR/11.jpg'
import Int_photo_12 from './INTERIOR/12.jpg'
import Int_photo_13 from './INTERIOR/13.jpg'
import Int_photo_14 from './INTERIOR/14.jpg'
import Int_photo_15 from './INTERIOR/15.jpg'
import Int_photo_16 from './INTERIOR/16.jpg'
import Int_photo_17 from './INTERIOR/17.jpg'
import Int_photo_18 from './INTERIOR/18.jpg'
import Int_photo_19 from './INTERIOR/19.jpg'
import Int_photo_20 from './INTERIOR/20.jpg'
import Int_photo_21 from './INTERIOR/21.jpg'

const All = [
    Int_photo_1, Int_photo_2, Int_photo_3, Int_photo_4, Int_photo_5,
    Int_photo_6, Int_photo_7, Int_photo_8, Int_photo_9, Int_photo_10,
    Int_photo_11, Int_photo_12, Int_photo_13, Int_photo_14, Int_photo_15,
    Int_photo_16, Int_photo_17 ,Int_photo_18, Int_photo_19, Int_photo_20,
    Int_photo_21
]


const useImageViewer = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
        document.body.style.overflow = 'hidden';
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        document.body.style.overflow = 'auto';
    };

    return { currentImage, isViewerOpen, openImageViewer, closeImageViewer };
};

export const AllSection = () => {
    const { currentImage, isViewerOpen, openImageViewer, closeImageViewer } = useImageViewer();

    return (
        <>
            <div className=" grid sm:grid-cols-3">
                {
                    All?.map((item, index) => (

                        <div key={index + "__All"} onClick={() => openImageViewer(index)} className="sm:max-w-sm sm:m-[2px] my-[5px] relative image-container ">
                            {/* <div className='img-overlay' /> */}
                            <img className="tab-img mmax-w-full sm:w-full" src={item} key={index + "__Floor"} />
                            {/* <div className="middle" style={{ fontFamily: "sans-serif" }}>
                                <div className="text-white text-2xl z-10 ">All Image</div>
                            </div> */}
                        </div>

                    ))
                }
            </div>
            {isViewerOpen && (
                <ImageViewer
                    src={All}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                    backgroundStyle={{ backgroundColor: '#000000c9' }}
                />
            )}
        </>
    )
}
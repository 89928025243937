import React from 'react'
import CivilDesing from './CivilDesing'

function Courses() {
  return (
   <>
   <CivilDesing/>
   </>
  )
}

export default Courses
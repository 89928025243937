import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";

const FooterItem = (props) => {
  const {
    name,
    children,
    isHoveredItem,
    handleHoveredItem,
    handleFooterClick,
  } = props;
  const location = useLocation();
  const isActive =
    (location.pathname === "/" && name === "home") ||
    location.pathname == `/${name}`;
  const isHovered = name == isHoveredItem;

  const handleCoursesClick = (route) => {
    handleFooterClick(route);
  };

  return (
    <>
      <div
        className="relative"
        onClick={() => {
          if (children !== "Courses") {
            handleFooterClick(name);
          }
        }}
        onMouseEnter={() => handleHoveredItem(name)}
        onMouseLeave={() => handleHoveredItem("")}
      >
        <a
          className={` hover:cursor-pointer font-semibold block lg:py-3 py-2 ${
            isActive || isHovered ? "text-[#33C2FC]" : ""
          }`}
        >
          {children !== "Courses" ? (
            children
          ) : (
            <div className="relative inline-block text-left">
              <div className="group ">
                <a
                  onClick={() => handleCoursesClick("/civil")}
                  className={`hover:text-[#33C2FC] ${
                    isActive || isHovered ? "text-[#33C2FC]" : ""
                  }  hover:cursor-pointer font-semibold block text-[14px]`}>
                  Courses
                  <span>
                    <i className="fa-solid fa-angle-down ml-2" />
                  </span>
                </a>
                <div className="absolute left-0 w-72 origin-top-left z-[9999] bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                  <div className="py-1">
                    <a
                      onClick={() => handleCoursesClick("/civil")}
                      className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Civil Design
                    </a>
                    <a
                      onClick={() =>
                        handleCoursesClick("/interiorAndArchitecture")
                      }
                      className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Interior And Architecture Design
                    </a>
                    <a
                      onClick={() => handleCoursesClick("/mechanicalDesign")}
                      className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Mechnical Design
                    </a>
                    <a
                      onClick={() => handleCoursesClick("/animationAndMedia")}
                      className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Animation And Multimedia
                    </a>
                    <a
                      onClick={() => handleCoursesClick("/graphicDesign")}
                      className="hover:text-[#33C2FC] hover:cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Graphic Design
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </a>
      </div>
    </>
  );
};

export default FooterItem;
